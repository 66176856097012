<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Add a Shipping Point</p>
        <v-row>
          <v-col
            cols="12" md="6"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedTown"
              ref="selectedTown"
              :items="townItems"
              item-text="name"
              item-value="id"
              outlined dense
              label="Select Shipping Town"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              v-model="shipping_spot"
              outlined dense ref="shipping_spot"
              label="Shipping Spot Name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="shipping_fee"
              outlined dense ref="shipping_fee"
              label="Shipping Fee"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <v-text-field
              type="number"
              v-model="time_interval_days"
              outlined dense ref="time_interval_days"
              label="Shippment Duration (Days)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addShippingPoint()"
        >
          Add Shipping Point
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      loading: false,
      shipping_spot: "",
      shipping_fee: "",
      selectedTown: "",
      time_interval_days: "",
      
      townItems: [],
    }
  },
  mounted(){
    document.title = "Manager: Market Place | Add Shipping Spot"
    this.getOrderTowns()
  },

  methods:{
    async getOrderTowns(){
      await axios
        .get('/api/v1/manager/market_place/get/order/towns/')
        .then(response => {
          this.townItems = response.data  // get the data and fill into campaigns
          console.log(this.townItems)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async addShippingPoint(){
        if(this.shipping_spot === ""){
            this.$store.commit('setSnackBarMessage', "Please input product name.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.shipping_spot.focus()  // this causes a an auto fucos to the element
        }
        else if(!this.shipping_fee){
          this.$store.commit('setSnackBarMessage', "Please input contact which clients will contact for inquires on whatsapp")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.shipping_fee.focus()  // this causes a an auto fucos to the element
        }
        else if(this.selectedTown === ""){
            this.$store.commit('setSnackBarMessage', "Please select product town.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.selectedTown.focus()  // this causes a an auto fucos to the element
        }
        else if(this.time_interval_days === ""){
            this.$store.commit('setSnackBarMessage', "Please input product price.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$refs.time_interval_days.focus()  // this causes a an auto fucos to the element
        }
        else {
            this.loading = true
  
            let formData = new FormData()
            formData.append('shipping_spot', this.shipping_spot)
            formData.append('shipping_fee', this.shipping_fee)
            formData.append('selectedTown', this.selectedTown)
            formData.append('time_interval_days', this.time_interval_days)

            await axios
                .post('/api/v1/manager/market_place/add/shipping/point', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    console.log(response.data)
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].town === "success"){
                      var navigation = this.$router
                      setTimeout(function(){
                        navigation.push('/marketplace/orders')
                      }, 3000)
                    }
                })
                .catch(error => {
                    if (error.response){
                        this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }else{
                        this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                    }
                })
            
            this.loading = false
        }

    },
  },
}
</script>
